import { createApp } from 'vue';
import App from './App.vue';
import { TUIComponents, TUICore, genTestUserSig } from './TUIKit';

const SDKAppID = 1400781368; // Your SDKAppID
const secretKey = 'd81284fa662e56ece567c326ff21cf8ee821c503cc0e9e0aa4c39ca6d4e0aea8'; //Your secretKey
const userID = 'demi'; // User ID

// init TUIKit
const TUIKit = TUICore.init({
  SDKAppID,
});
// TUIKit add TUIComponents
TUIKit.use(TUIComponents);

// login TUIKit
TUIKit.login({
  userID: userID,
  userSig: genTestUserSig({
    SDKAppID,
    secretKey,
    userID,
  }).userSig,
});

createApp(App).use(TUIKit).mount('#app');